<template>
    <a-drawer
        title="Добавить инвестпроект"
        placement="right"
        :width="drawerWidth"
        :visible="visible"
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <a-form-model
            ref="investForm"
            class="invest_form"
            :model="form"
            :rules="rules">
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Основная информация о проекте</h3>
                    <div class="st">шаг 1</div>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="project_name" label="Наименование проекта" prop="project_name">
                        <a-input
                            v-model="form.project_name"
                            placeholder="Напишите наименование проекта"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="organization" label="Организация" prop="organization">
                        <a-select
                            v-model="form.organization"
                            placeholder="Организация"
                            size="large"
                            class="w-full"
                            :loading="orgLoading"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null">
                            <a-select-option 
                                v-for="org in organization.results" 
                                :key="org.id" 
                                :value="org.id">
                                {{ org.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="region" label="Регион" prop="region">
                        <a-select
                            v-model="form.region"
                            placeholder="Выберите регион проекта"
                            size="large"
                            class="w-full"
                            :loading="regionLoading"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @dropdownVisibleChange="regionOpenSelect"
                            @change="changeRegion">
                            <a-select-option 
                                v-for="region in region.results" 
                                :key="region.id" 
                                :value="region.id">
                                {{ region.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="district" label="Район" prop="district">
                        <a-select
                            v-model="form.district"
                            placeholder="Выберите район проекта"
                            size="large"
                            class="w-full"
                            :disabled="form.region ? false : true"
                            :loading="districtLoading"
                            :default-active-first-option="false"
                            show-search
                            :filter-option="filterOption"
                            :not-found-content="null">
                            <a-select-option 
                                v-for="district in district.results" 
                                :key="district.id" 
                                :value="district.id">
                                {{ district.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="company_bin" label="БИН предприятия" prop="company_bin">
                        <a-input
                            v-model="form.company_bin"
                            placeholder="БИН предприятия"
                            :maxLength="12"
                            size="large"
                            @change="binChange">
                            <template v-if="binLoading" slot="suffix">
                                <a-spin size="small" />
                            </template>
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item ref="company_name" label="Наименование предприятия" prop="company_name">
                        <a-input
                            v-model="form.company_name"
                            placeholder="Наименование предприятия"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="direction" label="Направление" prop="direction">
                        <a-select 
                            v-model="form.direction"
                            placeholder="Укажите направление" 
                            size="large" 
                            class="w-full"
                            :default-active-first-option="false"
                            :not-found-content="null"
                            :loading="directionLoading">
                            <a-select-option 
                                v-for="direction in directionList.results" 
                                :key="direction.id" 
                                :value="direction.code">
                                {{ direction.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="industry" label="Отрасль" prop="industry">
                        <a-select 
                            v-model="form.industry"
                            placeholder="Укажите отрасль" 
                            size="large" 
                            class="w-full"
                            :default-active-first-option="false"
                            :not-found-content="null"
                            :loading="industryLoading">
                            <a-select-option 
                                v-for="industry in industryList.results" 
                                :key="industry.id" 
                                :value="industry.id">
                                {{ industry.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1">
                    <a-form-model-item ref="types_of_products" label="Вид продукции" prop="types_of_products">
                        <a-textarea
                            v-model="form.types_of_products"
                            :maxLength="1000"
                            :auto-size="{ minRows: 3, maxRows: 8 }"
                            placeholder="Опишите вид продукции"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="company_director_name" label="ФИО руководителя предприятия" prop="company_director_name">
                        <a-input
                            v-model="form.company_director_name"
                            placeholder="ФИО руководителя предприятия"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="company_phone" label="Контактный номер телефона" prop="company_phone">
                        <a-input
                            v-model="form.company_phone"
                            placeholder="Контактный номер телефона"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1">
                    <a-form-model-item ref="is_new" label="Стадия проекта" prop="is_new">
                        <a-select v-model="form.is_new" size="large" class="w-full">
                            <a-select-option value="true">
                                Новый
                            </a-select-option>
                            <a-select-option value="false">
                                Расширение
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1">
                    <a-form-model-item ref="foreign_investor_info" label="Иностранный инвестор" prop="foreign_investor_info">
                        <a-textarea
                            v-model="form.foreign_investor_info"
                            :maxLength="1000"
                            :auto-size="{ minRows: 3, maxRows: 8 }"
                            placeholder="Укажите иностранного инвестора (при наличии)"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="project_capacity" label="Мощность проекта" prop="project_capacity">
                        <a-input-number
                            v-model="form.project_capacity"
                            :step="0.01"
                            class="w-full"
                            placeholder="Укажите мощность проекта"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="measure_unit" label="Единица измерения" prop="measure_unit">
                        <a-select 
                            v-model="form.measure_unit"
                            placeholder="Единица измерения" 
                            size="large" 
                            class="w-full"
                            :default-active-first-option="false"
                            :not-found-content="null"
                            :loading="measureUnitLoading">
                            <a-select-option 
                                v-for="unit in measureUnitList.results" 
                                :key="unit.id" 
                                :value="unit.code">
                                {{ unit.string_view }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="date_start" label="Дата начала проекта" prop="date_start">
                        <a-date-picker v-model="form.date_start" valueFormat="YYYY-MM-DD" :showToday="false" size="large" placeholder="Дата начала проекта" class="w-full" />
                    </a-form-model-item>
                    <a-form-model-item ref="dead_line" label="Планируемый срок ввода в эксплуатацию (месяц, год)" prop="dead_line">
                        <a-date-picker v-model="form.dead_line" valueFormat="YYYY-MM-DD" :showToday="false" size="large" placeholder="Планируемый срок ввода в эксплуатацию" class="w-full" />
                    </a-form-model-item>
                </div>
            </div>
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Стоимость проекта</h3>
                    <div class="st">шаг 2</div>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="funds" label="Вся стоимость проекта, млн. тенге" prop="funds">
                        <a-input-number
                            v-model="form.funds"
                            :step="0.01"
                            class="w-full"
                            placeholder="Вся стоимость проекта"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="own_funds" label="Собственные средства, млн. тенге" prop="own_funds">
                        <a-input-number
                            v-model="form.own_funds"
                            :step="0.01"
                            class="w-full"
                            placeholder="Собственные средства"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="borrowed_funds" label="Заемные (АКК, БРК, СПК), млн. тенге" prop="borrowed_funds">
                        <a-input-number
                            v-model="form.borrowed_funds"
                            :step="0.01"
                            class="w-full"
                            placeholder="Заемные (АКК, БРК, СПК)"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="bank_funds" label="Банки второго уровня, млн. тенге" prop="bank_funds">
                        <a-input-number
                            v-model="form.bank_funds"
                            :step="0.01"
                            class="w-full"
                            placeholder="Банки второго уровня"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="fin_institute" label="Финансовый институт" prop="fin_institute">
                        <a-input
                            v-model="form.fin_institute"
                            placeholder="Финансовый институт (БКР/АКК/ХАЛЫК/другие)"
                            size="large" />
                    </a-form-model-item>
                </div>
            </div>
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Этап реализации</h3>
                    <div class="st">шаг 3</div>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="has_documentation" label="ПСД" prop="has_documentation">
                        <a-select v-model="form.has_documentation" size="large" class="w-full">
                            <a-select-option value="true">
                                Разработана
                            </a-select-option>
                            <a-select-option value="false">
                                Не разработана
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="installation_stage" label="Стадия СМР, %" prop="installation_stage">
                        <a-input-number
                            v-model="form.installation_stage"
                            class="w-full"
                            :min="0"
                            :max="100"
                            placeholder="Стадия СМР, %"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1">
                    <a-form-model-item ref="infrastructure_info" label="Проведенные инфраструктуры" prop="infrastructure_info">
                        <a-textarea
                            v-model="form.infrastructure_info"
                            :maxLength="1000"
                            :auto-size="{ minRows: 3, maxRows: 8 }"
                            placeholder="Опишите какая инфраструктура проведена"
                            size="large" />
                    </a-form-model-item>
                </div>
            </div>
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Закуп оборудования</h3>
                    <div class="st">шаг 4</div>
                </div>
                <div class="equipment_list" :class="form.equipment_purchases.length && 'mb-5'">
                    <div 
                        v-for="(equipment, index) in form.equipment_purchases" 
                        :key="equipment.key"
                        class="equipment_list__item">
                        <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                            <a-form-model-item 
                                label="Страна закупа оборудования" 
                                :prop="'equipment_purchases.' + index + '.country'"
                                :rules="{
                                    required: true,
                                    message: $t('wgr.field_require'),
                                    trigger: 'blur',
                                }">
                                <a-select 
                                    v-model="equipment.country" 
                                    placeholder="Укажите страну закупа оборудование" 
                                    size="large" 
                                    class="w-full"
                                    :default-active-first-option="false"
                                    :not-found-content="null"
                                    show-search
                                    :filter-option="filterOption"
                                    :loading="countryLoading"
                                    @dropdownVisibleChange="countryOpenSelect">
                                    <a-select-option 
                                        v-for="iCountry in countryList.results" 
                                        :key="iCountry.id" 
                                        :value="iCountry.id">
                                        {{ iCountry.string_view }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <a-form-model-item 
                                :prop="'equipment_purchases.' + index + '.delivery_date'" 
                                label="Сроки поставки оборудования (месяц, год)"
                                :rules="{
                                    required: true,
                                    message: $t('wgr.field_require'),
                                    trigger: 'blur',
                                }">
                                <a-date-picker 
                                    v-model="equipment.delivery_date"
                                    :showToday="false" 
                                    size="large" 
                                    valueFormat="YYYY-MM-DD"
                                    placeholder="Сроки поставки оборудования" 
                                    class="w-full" />
                            </a-form-model-item>
                        </div>
                        <div class="grid gap-0 md:gap-6 grid-cols-1">
                            <a-form-model-item 
                                :prop="'equipment_purchases.' + index + '.supplier_name'"  
                                label="Наименование планируемого поставщика"
                                :rules="{
                                    required: true,
                                    message: $t('wgr.field_require'),
                                    trigger: 'blur',
                                }">
                                <a-input
                                    v-model="equipment.supplier_name"
                                    placeholder="Наименование планируемого поставщика"
                                    size="large" />
                            </a-form-model-item>
                        </div>
                        <div v-if="form.equipment_purchases.length > 1" style="margin-top: -10px;">
                            <a-button type="link" size="small" @click="removeEquipment(index)">
                                Удалить
                            </a-button>
                        </div>
                    </div>
                </div>
                <a-button 
                    type="primary" 
                    ghost 
                    block 
                    size="large"
                    @click="addEquipment()">
                    Добавить источник закупа
                </a-button>
            </div>
            <div class="form_block">
                <div class="form_block__header">
                    <h3>Дополнительная информация</h3>
                    <div class="st">шаг 5</div>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="jobs_temporary" label="Временные рабочие места" prop="jobs_temporary">
                        <a-input-number
                            v-model="form.jobs_temporary"
                            class="w-full"
                            placeholder="Укажите количество временных рабочих мест"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="jobs_permanent" label="Постоянные рабочие места" prop="jobs_permanent">
                        <a-input-number
                            v-model="form.jobs_permanent"
                            class="w-full"
                            placeholder="Укажите количество постоянных рабочих мест"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="pasture_quantity" label="Пастбища, тыс. га" prop="pasture_quantity">
                        <a-input-number
                            v-model="form.pasture_quantity"
                            class="w-full"
                            :step="0.01"
                            placeholder="Укажите площадь пастбища"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="plowed_field_quantity" label="Пашни, тыс. га" prop="plowed_field_quantity">
                        <a-input-number
                            v-model="form.plowed_field_quantity"
                            class="w-full"
                            :step="0.01"
                            placeholder="Укажите площадь пашни"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1 md:grid-cols-2">
                    <a-form-model-item ref="cadaster" label="Кадастровый номер ЗУ" prop="cadaster">
                        <a-input
                            v-model="form.cadaster"
                            placeholder="Кадастровый номер ЗУ"
                            size="large" />
                    </a-form-model-item>
                    <a-form-model-item ref="land_plot" label="Земельный участок (количество гектар)" prop="land_plot">
                        <a-input-number
                            v-model="form.land_plot"
                            class="w-full"
                            :step="0.01"
                            placeholder="Укажите земельный участок"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1">
                    <a-form-model-item ref="questions" label="Проблемные вопросы" prop="questions">
                        <a-textarea
                            v-model="form.questions"
                            :maxLength="1000"
                            :auto-size="{ minRows: 3, maxRows: 8 }"
                            placeholder="Опишите проблемные вопросы (при наличии)"
                            size="large" />
                    </a-form-model-item>
                </div>
                <div class="grid gap-0 md:gap-6 grid-cols-1">
                    <a-form-model-item ref="work_experience" label="Опыт работы" prop="work_experience">
                        <a-textarea
                            v-model="form.work_experience"
                            :maxLength="1000"
                            :auto-size="{ minRows: 3, maxRows: 8 }"
                            placeholder="Укажите опыт работы (при наличии)"
                            size="large" />
                    </a-form-model-item>
                </div>
            </div>
            <div class="footer_buttons">
                <a-button type="primary" size="large" :loading="loading" @click="formSubmit()">
                    Создать проект
                </a-button>
                <a-button type="ui" size="large" @click="visible = false">
                    Отмена
                </a-button>
            </div>
        </a-form-model>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'

let binTimer;
const formData = {
    region: null,
    district: null,
    measure_unit: null,
    date_start: null,
    industry: null,
    project_name: "",
    company_name: "",
    company_bin: "",
    direction: null,
    company_director_name: "",
    company_phone: "",
    is_new: "true",
    foreign_investor_info: "",
    project_capacity: "",
    funds: "",
    own_funds: "",
    borrowed_funds: "",
    organization: null,
    bank_funds: "",
    has_documentation: null,
    installation_stage: "",
    infrastructure_info: "",
    country: null,
    land_plot: null,
    srok: "",
    jobs_temporary: "",
    jobs_permanent: "",
    pasture_quantity: "",
    plowed_field_quantity: "",
    dead_line: null,
    cadaster: "",
    questions: "",
    work_experience: "",
    fin_institute: "",
    equipment_purchases: [
        {
            key: Date.now(),
            country: null,
            supplier_name: '',
            delivery_date: null
        }
    ]
}

export default {
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        drawerWidth() {
            if(this.windowWidth > 1100)
                return 1100
            else {
                return '100%'
            }
        }
    },
    data() {
        return {
            visible: false,
            rules: {
                measure_unit: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                industry: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                organization: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                region: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                district: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                company_name: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                company_bin: [
                    { required: true, message: this.$t('wgr.field_require'), trigger: 'blur' },
                    { min: 12, max: 12, message: 'Введите 12 символов', trigger: 'blur' }
                ],
                direction: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                types_of_products: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                company_director_name: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                company_phone: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                is_new: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                project_capacity: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                funds: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                own_funds: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                borrowed_funds: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                bank_funds: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                has_documentation: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                installation_stage: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                infrastructure_info: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                country: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                srok: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                p_name_equipment: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                jobs_temporary: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                jobs_permanent: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                pasture_quantity: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                plowed_field_quantity: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                dead_line: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                cadaster: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                project_name: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                fin_institute: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                date_start: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }],
                land_plot: [{ required: true, message: this.$t('wgr.field_require'), trigger: 'blur' }]
            },
            form: {...formData},
            region: {
                results: []
            },
            organization: {
                results: []
            },
            district: {
                results: []
            },
            regionLoading: false,
            orgLoading: false,
            districtLoading: false,
            countryLoading: false,
            directionLoading: false,
            industryLoading: false,
            measureUnitLoading: false,
            loading: false,
            binLoading: false,
            countryList: {
                results: []
            },
            directionList: {
                results: []
            },
            measureUnitList: {
                results: []
            },
            industryList: {
                results: []
            }
        }
    },
    methods: {
        async getIndustry() {
            if(!this.industryList.results.length) {
                try {
                    this.industryLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectIndustryModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.industryList.results = data.selectList
                        this.form.industry = data.selectList[0].id
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.industryLoading = false
                }
            } else
                this.form.industry = this.industryList.results[0].id
        },
        async getMeasureUnit() {
            if(!this.measureUnitList.results.length) {
                try {
                    this.measureUnitLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectMeasureUnitModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.measureUnitList.results = data.selectList
                        this.form.measure_unit = data.selectList[0].code
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.measureUnitLoading = false
                }
            } else
                this.form.measure_unit = this.measureUnitList.results[0].code
        },
        async getOrganization() {
            if(!this.organization.results.length) {
                try {
                    this.orgLoading = true
                    const params = {
                        permission_type: "create_invest_projects_info"
                    }
                    const { data } = await this.$http.get('/contractor_permissions/organizations/', {
                        params
                    })
                    if(data?.length) {
                        this.organization.results = data
                        this.form.organization = data[0].id
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.orgLoading = false
                }
            }
        },
        async getDirection() {
            if(!this.directionList.results.length) {
                try {
                    this.directionLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectDirectionModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.directionList.results = data.selectList
                        this.form.direction = data.selectList[0].code
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.directionLoading = false
                }
            } else
                this.form.direction = this.directionList.results[0].code
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        formSubmit() {
            this.$refs['investForm'].validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const formData = {...this.form}
                        if(formData.is_new === 'true') {
                            formData.is_new = true
                        } else {
                            formData.is_new = false
                        }
                        if(formData.has_documentation === 'true') {
                            formData.has_documentation = true
                        } else {
                            formData.has_documentation = false
                        }

                        const { data } = await this.$http.post('/invest_projects_info/', formData)
                        if(data) {
                            this.visible = false
                            this.$message.success('Проект успешно создан')
                            eventBus.$emit('update_invest_list')
                        }
                    } catch(error) {
                        console.log(error)
                        this.$message.error('Ошибка сохранения')
                    } finally{
                        this.loading = false
                    }
                } else
                    return false
            })
        },
        removeEquipment(index) {
            this.form.equipment_purchases.splice(index, 1)
        },
        addEquipment() {
            this.form.equipment_purchases.push({
                country: null,
                supplier_name: '',
                delivery_date: null,
                key: Date.now(),
            })
        },
        binChange(e) {
            clearTimeout(binTimer)
            binTimer = setTimeout(async () => {
                const bin = e.target.value
                if(bin.length === 12) {
                    try {
                        this.binLoading = true
                        const { data } = await this.$http.get('/catalogs/contractor_from_egov/', {
                            params: {
                                bin
                            }
                        })
                        if(data?.success && data.obj) {
                            if(data.obj.fio)
                                this.form.company_director_name = data.obj.fio
                            if(data.obj.name)
                                this.form.company_name = data.obj.name
                        }
                    } catch(e) {
                        console.log(e)
                    } finally {
                        this.binLoading = false
                    }
                }
            }, 300)
        },
        afterVisibleChange(vis) {
            if(!vis) {
                this.form = {...formData}
                this.organization.results = []
            } else {
                this.getDirection()
                this.getOrganization()
                this.getMeasureUnit()
                this.getIndustry()
            }
        },
        regionOpenSelect(vis) {
            if(vis)
                this.getRegions()
        },
        countryOpenSelect(vis) {
            if(vis)
                this.getCountryList()
        },
        async getCountryList() {
            if(!this.countryList.results.length) {
                try {
                    this.countryLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectCountryModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.countryList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.countryLoading = false
                }
            }
        },
        async getDistrict() {
            try {
                this.districtLoading = true
                const params = {
                    model: "invest_projects_info.InvestProjectDistrictModel",
                    filters: {
                        region: this.form.region
                    }
                }
                const { data } = await this.$http.get('/app_info/select_list/', {
                    params
                })
                if(data?.selectList?.length) {
                    this.district.results = data.selectList
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.districtLoading = false
            }
        },
        async getRegions() {
            if(!this.region.results.length) {
                try {
                    this.regionLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectRegionModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.region.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.regionLoading = false
                }
            }
        },
        changeRegion(val) {
            if(val) {
                this.district.results = []
                this.form.district = null
                this.getDistrict()
            }
        }
    },
    mounted(){
        eventBus.$on('add_invest_proejct', () => {
            this.visible = true
        })
    },
    beforeDestroy() {
        eventBus.$off('add_invest_proejct')
    }
}
</script>

<style lang="scss" scoped>
.equipment_list{
    &__item{
        &:not(:last-child){
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--border2);
        }
    }
}
.invest_form{
    .form_block{
        padding: 15px;
        border: 1px solid var(--border2);
        border-radius: var(--borderRadius);
        margin-bottom: 20px;
        @media (min-width: 768px) {
            padding: 30px;
        }
        &__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            h3{
                font-size: 20px;
                color: #000000;
                font-weight: 400;
                margin: 0px;
            }
            .st{
                color: #000000;
                font-size: 18px;
                opacity: 0.3;
                padding-left: 15px;
                text-wrap: nowrap;
            }
        }
    }
    .footer_buttons{
        display: flex;
        align-items: center;
        &::v-deep{
            .ant-btn{
                &:not(:last-child){
                    margin-right: 10px;
                }
                &.ant-btn-lg{
                    height: 50px;
                    padding: 0 25px;
                }
            }
        }
    }
}
</style>